import { Button, Modal } from "react-bootstrap";
import { getInterestList, setLoader } from "../../../store/slice/masterSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import * as API from "../../../utils/api.service";
import Cookies from "js-cookie";

const InterestModal = ({ showModal6, handleClose6 }) => {
    const { InterestList: { data: InterestList } } = useSelector((state) => state.master);
    const dispatch = useDispatch();
    const userData = Cookies.get('dataCA') ? JSON.parse(Cookies.get('dataCA')) : {};
    const { register: registerForm1, handleSubmit: handleSubmitForm1, formState: { errors: errors1 } } = useForm({
        defaultValues: {
            interests: userData.interest_id ? userData.interest_id.split(',') : [],
        },
    });

    const onSubmitForm1 = async (body) => {
        const interest_id = body.interests.join(',');

        try {
            dispatch(setLoader(true));
            let { data, code, message } = await API.selectInterest({ interest_id, device_type: "A", device_token: "0" });
            dispatch(setLoader(false));
            if (code === '1') {
                TOAST_SUCCESS(message);
                handleClose6();
                Cookies.set('dataCA', JSON.stringify({ ...userData, interest_id }), { expires: 2 });
            }
        } catch (err) {
            dispatch(setLoader(false));
            TOAST_ERROR(err.message);
        }

    };

    useEffect(() => {
        dispatch(getInterestList());
    }, []);

    return (
        <div className="interests_modal-container">
            <Modal show={showModal6} onHide={handleClose6} centered>
                <form onSubmit={handleSubmitForm1(onSubmitForm1)}>

                    <Modal.Body>
                        <h2 className="fs-20 fw-7">Interests</h2>
                        {
                            InterestList && InterestList.map(({ interest_id, name, category_image_link }) =>
                                <div className="interests_boxs">
                                    <ul>
                                        <li className="interests_box mt-1">
                                            <input
                                                type="checkbox"
                                                value={interest_id}
                                                name="interests"
                                                id={`checkbox-${interest_id}`}
                                                defaultChecked={userData?.interest_id?.includes(interest_id)}
                                                {...registerForm1('interests', {
                                                    validate: (value) => value?.length >= 1 || 'Please select at least 1 interests.',
                                                })}
                                            />

                                            <div className="left">
                                                <img width={50} src={category_image_link} className="interst_icons" />
                                                <span className="fs-15 fw-6 dark-black">{name}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )
                        }
                        {errors1.interests && (
                            <p role="alert" className="mt-2 text-danger">
                                {errors1.interests.message}
                            </p>
                        )}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose6}>Close</Button>
                        <Button variant="primary" type="submit">Update</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )
}

export default InterestModal;