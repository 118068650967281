import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as API from "../../utils/api.service";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
import { getCountryList, setLoader } from "../../store/slice/masterSlice";
import CommonHeader from "../CommonHeader";
import LeftSideBar from "../LeftSideBar";
import backArrow from "../../back_arrow.png";

const ContactUs = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { countryList: { data: countryList } } = useSelector((state) => state.master);
    const location = useLocation()
    const param = location.pathname;
    console.log(param, "param");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        try {
            dispatch(setLoader(true));
            const response = await API.contactUs(data);
            dispatch(setLoader(false));
            if (response.code === "1") {
                TOAST_SUCCESS("Your message has been sent successfully!");
                navigate("/")
            }
        } catch (error) {
            dispatch(setLoader(false));
            TOAST_ERROR("Failed to send message. Please try again later.");
        }
    };
    useEffect(() => {
        dispatch(getCountryList());
    }, []);

    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isValidNumber = /^[0-9]+$/.test(keyValue);

        if (!isValidNumber) {
            event.preventDefault();
        }
    };

    return (
        <>
            <div className="d-flex align-items-center">
                <img src={backArrow} height={25} width={25} className='m-5' onClick={() => navigate(-1)} />
            </div>
            <div className="d-flex justify-content-center align-items-center vh-50">
                <div className="content_changepw content-contactus">
                    <h2 className="title-26px">Contact us</h2>
                    <p className="fs-14 fw-6 gray1 text-start">
                        Please feel free to talk to us if you have any questions. We endeavour
                        to answer within 24 hours.
                    </p>

                    <form className="form_profile_pw common_form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="mb-4 col-lg-6 col-md-6 col-12 form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="full_name"
                                    placeholder="Full Name"
                                    {...register("full_name", {
                                        required: "Please enter Full Name.",
                                        pattern: {
                                            value: /^[A-Za-z]+(?:[-\s][A-Za-z]+)*$/,
                                            message: "Fullname must start and end with a letter.",
                                        },
                                    })}
                                />
                                <label htmlFor="full_name">Full Name</label>
                                {errors.full_name && (
                                    <p role="alert" className="text-danger">
                                        {errors.full_name.message}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4 col-lg-6 col-md-6 col-12 form-floating">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    {...register("email", {
                                        required: "Please enter Email.",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Please enter a valid email address",
                                        },
                                    })}
                                />
                                <label htmlFor="email">Email</label>
                                {errors.email && (
                                    <p role="alert" className="text-danger">
                                        {errors.email.message}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4 col-lg-6 col-md-6 col-12 form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Subject"
                                    id="subject"
                                    {...register("subject", {
                                        required: "Please enter Subject.",
                                    })}
                                />
                                <label htmlFor="subject">Subject</label>
                                {errors.subject && (
                                    <p role="alert" className="text-danger">
                                        {errors.subject.message}
                                    </p>
                                )}
                            </div>

                            <div className="mb-4 col-lg-6 col-md-6 col-12 form-floating mobile-number position-relative">
                                <input
                                    {...register("mobile_number", {
                                        required: "Please enter Mobile Number and Country Code.",
                                        pattern: {
                                            value: /^[0-9]{10}$/,
                                            message: "Mobile Number only contain numbers",
                                        },
                                    })}
                                    type="text"
                                    onKeyPress={handleKeyPress}
                                    className="form-control"
                                    id="floatingInput exampleInputEmail1"
                                    name="mobile_number"
                                    placeholder="Mobile Number"
                                    maxLength={10}
                                />
                                <label for="floatingInput">Mobile Number</label>
                                <select class="form-select form-select-lg nub-drop"
                                    aria-label="Large select example" name="country_code"
                                    {...register("country_code", {
                                        required: "",

                                    })}>
                                    {
                                        countryList?.map((country) =>
                                            <>
                                                <option key={country?.country_id} value={country?.country_id}>
                                                    {country?.phonecode}
                                                </option>
                                            </>
                                        )
                                    }
                                </select>
                                {(errors.mobile_number || errors.country_code) && (
                                    <p role="alert" className="text-danger">
                                        {errors.mobile_number?.message}{errors.country_code?.message}
                                    </p>
                                )}
                            </div>
                            <div className="mb-4 col-lg-12 col-md-12 col-12">
                                <textarea
                                    className="form-control"
                                    placeholder="Bio/Description"
                                    id="description"
                                    style={{ height: "130px", paddingTop: "15px" }}
                                    {...register("description", {
                                        required: "Please enter Description.",
                                    })}
                                ></textarea>
                                {errors.description && (
                                    <p role="alert" className="text-danger">
                                        {errors.description.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="btn-update_profile">
                            <button
                                type="submit"
                                className="btn btn-theme m-auto w-100"
                                disabled={isLoading}
                            >
                                {isLoading ? "Sending..." : "Send Message"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
