import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../../utils/api.service";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { getCardDetails } from "../../../store/slice/cardSlice";
import { setLoader } from "../../../store/slice/masterSlice";
import StripePayment from "../../Payment";


const BillIngModal = ({
    showBill, handleBillClose, billData
}) => {

    return (
        <>

            {/* <!-- Modal billing-popup --> */}

            <Modal show={showBill} onHide={handleBillClose} centered dialogClassName="modal-xl" id="billing-info">
                <Modal.Body style={{background : "none"}}>
            {/* <Modal show={showBill} onHide={handleBillClose} dialogClassName="modal-xl" id="billing-info">
                <Modal.Body > */}

                    <div className="subscription-billing-information_with-cards">
                        <div className="login-section sign-up-section" id="home"> 
                             <div className="container"> 
                                 <div className="row align-items-center justify-content-center gx-5">
                                    <div className="col-xl-12 col-lg-12 col-12">
                                        <div className="subscription-section">
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-12">
                                                    <div className="bg-logo-blue subscription-details">
                                                        <div className="logo-img mb-3">
                                                            <img src="assets/img/logo-white-large.svg" className="img-fluid" alt="Logo" />
                                                        </div>
                                                        <div className="subscription-destils-inner">
                                                            <div className="subscription-title">
                                                                <h3>{billData?.plan_name}</h3>
                                                            </div>
                                                            <div className="subscription-body">
                                                                <h3>${billData?.pricing} <span>Payable amount</span></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-8 col-lg-8 col-12">
                                                    <div className="row justify-content-center">
                                                        <div className="col-xl-12 col-lg-10 col-12">
                                                            <div className="choose-your-plan">
                                                                <div className="login-title text-center mb-5">
                                                                    <h3>Billing Information</h3>
                                                                    <p className="select-card gray1">Add your card detaiils.</p>
                                                                </div>
                                                                <div className="login-tabs">
                                                                    <StripePayment billData={billData} handleBillClose={handleBillClose} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                             </div> 
                        </div>
                    </div> 
                </Modal.Body>
            </Modal>

        </>
    )
}

export default BillIngModal;