import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBronzeDetails } from "../../store/slice/bronzeSlice";
import { getUserDetails } from "../../store/slice/userSlice";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, LinkedinShareButton, TelegramShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon, LinkedinIcon, TelegramIcon } from 'react-share';
import { slice } from "lodash";


const PopUpModals = ({
    showModal3, handleClose3,
    showModal4, handleClose4,
    showModal7, handleClose7,
    page, course_id, category_id,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { bronzeDetails: { data: bronzeDetails } } = useSelector((state) => state.bronze);
    console.log('bronzeDetails :', bronzeDetails);
    const { userDetails: { data: userDetails } } = useSelector((state) => state.user);
    const [selectedBadge, setSelectedBadge] = useState(null);
    const [showSilverBadgeModal, setShowSilverBadgeModal] = useState(false);

    const handleBadgeClick = (badge) => {
        setSelectedBadge(badge);
        setShowSilverBadgeModal(true);
    }

    const handleSilverBadgeModalClose = () => {
        setShowSilverBadgeModal(false);
    }


    useEffect(() => {
        // if (!showModal1 && showModal1 !== false) {
        dispatch(getBronzeDetails());
        dispatch(getUserDetails());
        // }
    }, []);



    const bronzeBadge = userDetails?.nextBronzeData;
    const inputRef = useRef(null);

    const copyToClipboard = () => {
        if (inputRef.current) {
            inputRef.current.select();
            document.execCommand("copy");
        }
    };
    const shareUrl = userDetails?.referal_code;
    const title = "Check this out referral code!";
    const [showSharingOptions, setShowSharingOptions] = useState(false);

    const handleInviteNowClick = () => {
        setShowSharingOptions(!showSharingOptions);
    };

    return (
        <>
            <Modal show={showModal3} onHide={handleClose3} size={"xl"} centered className="badge-detail-modal">
                <Modal.Body style={{ backgroundColor: "transparent" }}>

                    {/* <div className="badge-details box_design-07">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md-12 col-lg-4 cust-left-badgedetail position-relative">
                                    <div className="badge-details_left">
                                        <div className="content-badge-detail-left">
                                            <img src="./assets/img/icon-gain-badges.svg" alt="" className="img-fluid bronze-001" />
                                            <p className="fs-22 fw-6 dark-black text-center">{bronzeBadge?.bronze_name}</p>
                                            <div className="badge-points">
                                                <img src="./assets/img/star-rating.svg" alt="" className="star-001" />
                                                <p className="fs-14 fw-5 text-white"><span className="fw-7">{userDetails?.points}</span> Points</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-8 cust-right-badgedetail position-relative">
                                    {bronzeDetails && bronzeDetails?.length > 0 && bronzeBadge && (
                                        <>
                                            <div className="silver-badge">
                                                <p className="fs-18 fw-4 dark black">Required <span className="fw-7">{bronzeBadge?.required_lessons}</span> more lesson to unlock</p>
                                                <p className="fw-18 fw-7 carkblue">{bronzeBadge?.bronze_name} Badge</p>
                                            </div>
                                            <div className="bronze-silver_badges">
                                                <div className="row">
                                                    {bronzeDetails?.slice()?.reverse()?.map((badge, index) => (
                                                        <div className="col-md-4">
                                                            <div className="bronze-silver_badge-box position-relative" key={index} onClick={() => handleBadgeClick(badge)}>
                                                                <img src={badge.bronze_image_link} alt="" className="img-fluid badge_image2 active" />
                                                                <p className="fs-16 fw-6 dark-black text-center">{badge.bronze_name}</p>
                                                                <p className="fs-12 fw-4 gray1"><span className="fw-7">{badge.required_lessons}</span>lessons</p>
                                                                {index < bronzeDetails.length - 1 && <img src="./assets/img/line-badge-gray.png" alt="" className="linde-badge" />}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div class="badge-details box_design-07">
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-md-12 col-lg-4 cust-left-badgedetail position-relative">
                                    <div className="badge-details_left">
                                        <div className="content-badge-detail-left">
                                            <img src="./assets/img/icon-gain-badges.svg" alt="" className="img-fluid bronze-001" />
                                            <p className="fs-22 fw-6 dark-black text-center">{bronzeBadge?.bronze_name}</p>
                                            <div className="badge-points">
                                                <img src="./assets/img/star-rating.svg" alt="" className="star-001" />
                                                <p className="fs-14 fw-5 text-white"><span className="fw-7">{userDetails?.points}</span> Points</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-8 cust-right-badgedetail position-relative">
                                    {
                                        bronzeDetails && bronzeDetails?.length > 0 && bronzeBadge && (
                                            <>
                                                <div className="silver-badge">
                                                    <p className="fs-18 fw-4 dark black">Required <span className="fw-7">{bronzeBadge?.required_lessons}</span> more lesson to unlock</p>
                                                    <p className="fw-18 fw-7 carkblue">{bronzeBadge?.bronze_name} Badge</p>
                                                </div>
                                                <div className="bronze-silver_badges">
                                                    <div className="row">
                                                        {bronzeDetails?.slice().reverse().map((badge, index) => (
                                                            <>
                                                                <div className="col-4">
                                                                    <div className="bronze-silver_badge-box position-relative" onClick={() => handleBadgeClick(badge)}>
                                                                        <img src={badge.bronze_image_link} alt="" className="img-fluid badge_image2 active" />
                                                                        <p className="fs-16 fw-6 dark-black text-center">{badge.bronze_name}</p>
                                                                        <p className="fs-12 fw-4 gray1">
                                                                            <span className="fw-7">{badge.required_lessons}</span> lessons
                                                                        </p>
                                                                        {index % 3 === 2 && index < bronzeDetails.length - 1 && (
                                                                            <img src="./assets/img/line-badge-horizontal.png" alt="" className="line-badge-horizontal" />
                                                                        )}
                                                                        {index % 3 === 0 && index < bronzeDetails.length - 3 && (
                                                                            <img src="./assets/img/line-badge-vertical.png" alt="" className="line-badge-vertical" />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {index % 3 === 2 && index < bronzeDetails.length - 1 && (
                                                                    <div className="w-100"></div>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    }

                                    {/* <div className="silver-badge">
                                        <p className="fs-18 fw-4 dark black">Required <span className="fw-7">10{bronzeBadge?.required_lessons}</span> more lesson to unlock</p>
                                        <p className="fw-18 fw-7 carkblue">{bronzeBadge?.bronze_name}gold Badge</p>
                                    </div>

                                    <div class="bronze-silver_badges">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="bronze-silver_badge-box position-relative">
                                                    <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">10</span>lessons</p>
                                                    <img src="./assets/img/line-badge-gray.png" alt="" class="linde-badge" />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="bronze-silver_badge-box position-relative" data-bs-toggle="modal"
                                                    data-bs-target="#silver-badge_modal">
                                                     <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">20</span>lessons</p>
                                                    <img src="./assets/img/line-badge-gray.png" alt="" class="linde-badge" />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="bronze-silver_badge-box position-relative">
                                                <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">30</span>lessons</p>
                                                    <img src="./assets/img/line-badge-verticle.png" alt="" class="linde-badge-vertical2" />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="bronze-silver_badge-box position-relative d-none">
                                                <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">40</span>lessons</p>
                                                    <img src="./assets/img/line-badge-gray.png" alt="" class="linde-badge" />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="bronze-silver_badge-box position-relative">
                                                <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">50</span>lessons</p>
                                                    <img src="./assets/img/line-badge-gray.png" alt="" class="linde-badge" />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="bronze-silver_badge-box position-relative">
                                                <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">60</span>lessons</p>
                                                    <img src="./assets/img/line-badge-verticle.png" alt="" class="linde-badge-vertical2" />
                                                </div>
                                            </div>
                                            <div class="col-4 d-none">
                                                <div class="bronze-silver_badge-box position-relative">
                                                <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">70</span>lessons</p>
                                                    <img src="./assets/img/line-badge-gray.png" alt="" class="linde-badge" />
                                                </div>

                                            </div>
                                            <div class="col-4 d-none">
                                                <div class="bronze-silver_badge-box position-relative">
                                                <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">80</span>lessons</p>
                                                    <img src="./assets/img/line-badge-gray.png" alt="" class="linde-badge" />
                                                </div>
                                            </div>
                                            <div class="col-4 d-none">
                                                <div class="bronze-silver_badge-box">
                                                <img src="./assets/img/icon-gain-badges.svg" alt="" class="img-fluid bronze-001" />
                                                    <p class="fs-16 fw-6 dark-black text-center">Silver</p>
                                                    <p class="fs-12 fw-4 gray1"><span class="fw-7">90</span>lessons</p>
                                                    <img src="./assets/img/line-badge-gray.png" alt="" class="linde-badge" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            {/* <!-- Modal Silver Badge popup --> */}
            <Modal show={showSilverBadgeModal} centered onHide={handleSilverBadgeModalClose}>
                <Modal.Body>
                    <div className="theme-small-modal">
                        <img src={selectedBadge?.bronze_image_link} className="img-fluid" alt="" />
                        <h2>{selectedBadge?.bronze_name} Badge</h2>
                        <p>To get a {selectedBadge?.bronze_name} badge, you need to complete {selectedBadge?.required_lessons} lessons.</p>
                        <Button variant="theme" className="w-100 mt-5" onClick={handleSilverBadgeModalClose}>Ok</Button> {/* Handle closing */}
                    </div>
                </Modal.Body>
            </Modal>

            {/* <!-- Modal Refer & Earn Popup --> */}
            <Modal show={showModal4} onHide={handleClose4} className="fade modal-refer-earn" id="refer-earn_popup" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" size={'xl'} centered={true}>
                <Modal.Dialog>
                    <Modal.Body className="p-0">
                        <div className="badge-details box_design-07" >
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-md-12 col-lg-6 cust-left-refer_earn position-relative">
                                        <div className="refer-earn-container">
                                            <div className="section-title refer-earn-box">
                                                <img src="assets/img/people1.svg" className="img-fluid img-left" alt="People" />
                                                <img src="assets/img/people2.svg" className="img-fluid img-right" alt="People" />
                                                <img src="assets/img/people3.png" className="img-fluid img-right-top" alt="People" />
                                                <p>Refer & Earn</p>
                                                <h3>Refer a friend and get a {userDetails?.refer_discount} discount on your next subscription</h3>
                                            </div>
                                            <div className="copyclip-board text-center" style={{ "borderBottomLeftRadius": "28px" }}>
                                                <div className="position-relative box_design-clipboard w-75" onClick={copyToClipboard}>
                                                    <input ref={inputRef} className="copy-input" type="text" value={userDetails?.referal_code} readOnly />
                                                    <span className="tap-to-copy gray1 fs-14 fw-4">Tap to copy</span>
                                                    <input className="copy-clip" type="button" value="" onClick={copyToClipboard} />
                                                </div>
                                                <Button variant="theme" className="w-75" onClick={handleInviteNowClick}>Invite Now</Button>
                                                {showSharingOptions && (
                                                    <div className="sharing-options d-flex justify-content-between mx-5 mt-3">
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <FacebookShareButton url={shareUrl} quote={title}>
                                                                <FacebookIcon size={32} round />
                                                            </FacebookShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <TwitterShareButton url={shareUrl} title={title}>
                                                                <TwitterIcon size={32} round />
                                                            </TwitterShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <WhatsappShareButton url={shareUrl} title={title}>
                                                                <WhatsappIcon size={32} round />
                                                            </WhatsappShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <EmailShareButton url={shareUrl} title={title}>
                                                                <EmailIcon size={32} round />
                                                            </EmailShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <LinkedinShareButton url={shareUrl} title={title}>
                                                                <LinkedinIcon size={32} round />
                                                            </LinkedinShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <TelegramShareButton url={shareUrl} title={title}>
                                                                <TelegramIcon size={32} round />
                                                            </TelegramShareButton>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-6 cust-right-refer_earn position-relative">
                                        <div className="how-it-work">
                                            <h3 className="fs-20 fw-7 dark-black">How it works?</h3>
                                            <p className="fs-14 fw-6 dark-black text-01">
                                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                                                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                                Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                                                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                                            </p>
                                            <p className="fs-14 fw-5 text-02">
                                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                                                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                                Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                                                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                                            </p>
                                            <p className="fs-14 fw-5 text-02">
                                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                                                Exercitation veniam consequat sunt nostrud amet.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>

            {/* <!-- Modal Currect Answer popup --> */}
            <Modal
                show={showModal7}
                onHide={handleClose7}
                centered
                aria-labelledby="exampleModalLabel"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Correct Answer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="theme-small-modal">
                        <img src="assets/img/currect-ans-icon.png" className="img-fluid" alt="Correct Answer Icon" />
                        <h2>Correct Answer</h2>
                        <p>
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                            duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non
                            deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit
                            mollit. Exercitation veniam consequat sunt nostrud amet.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="theme" onClick={() => navigate('/practice-3', { state: { page, course_id, category_id } })}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PopUpModals;