import request from "./axios.service";
import { GET, POST, PUT, PATCH, DELETE, BASE_URL, USER, OPTION, COUNT_PER_PAGE } from "../app.config";
import { Decryption } from "./common.service";

// <-----------------auth------------------>
export const signup = (body) => {
  return request(`${BASE_URL}/${USER}/auth/signup`, POST, false, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const sendOtp = (body) => {
  return request(`${BASE_URL}/${USER}/auth/send_otp`, POST, false, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const getCountryList = () => {
  return request(`${BASE_URL}/${USER}/auth/country_list`, GET, false, {})
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      console.error('API Error:', error);
      throw error;
    });
};

export const login = (body) => {
  return request(`${BASE_URL}/${USER}/auth/login`, POST, false, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const forgotPassword = (body) => {
  return request(`${BASE_URL}/${USER}/auth/forgot_password`, POST, false, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const resetPassword = (body) => {
  return request(`${BASE_URL}/${USER}/auth/reset_password`, POST, false, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};


export const deleteAccount = (body) => {
  return request(`${BASE_URL}/${USER}/auth/delete_account`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const getInterestList = () => {
  return request(`${BASE_URL}/${USER}/auth/interest_list`, GET, true, {})
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      console.error('API Error:', error);
      throw error;
    });
};

export const selectInterest = (body) => {
  return request(`${BASE_URL}/${USER}/auth/select_interest`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const getSubListApi = () => {
  return request(`${BASE_URL}/${USER}/subscription/subscription_list`, GET, true, {})
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      console.error('API Error:', error);
      throw error;
    });
};

export const addSubscription = (body) => {
  return request(`${BASE_URL}/${USER}/subscription/add_subscription`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const addCard = (body) => {
  return request(`${BASE_URL}/${USER}/card/add_card`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const showCard = (body) => {
  return request(`${BASE_URL}/${USER}/card/show_card_list`, GET, true, {})
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const logout = () => {
  return request(`${BASE_URL}/${USER}/auth/logout`, POST, true, {})
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};


// <-----------------Dashboard------------------>

export const categoryList = () => {
  return request(`${BASE_URL}/${USER}/home/all_categories`, GET, true, {})
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const feturedCourseList = (body) => {
  return request(`${BASE_URL}/${USER}/home/fetured_course`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const categorywiseCourseList = (body) => {
  return request(`${BASE_URL}/${USER}/home/category_wise_course`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const searchCourse = (body) => {
  return request(`${BASE_URL}/${USER}/home/course_search`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const notificationListing = (body) => {
  return request(`${BASE_URL}/${USER}/common/notification_list`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const progressReport = (body) => {
  return request(`${BASE_URL}/${USER}/common/progress_report`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const downloadList = (body) => {
  return request(`${BASE_URL}/${USER}/common/download_list`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const courseDetails = (body) => {
  return request(`${BASE_URL}/${USER}/home/course_details`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const lessonList = (body) => {
  return request(`${BASE_URL}/${USER}/home/lesson_list`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

//<--------------Static Pages------------------->
export const staticPages = () => {
  return request(`${BASE_URL}/${USER}/common/app_content_for_website`, GET, true, {})
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const faqPage = () => {
  return request(`${BASE_URL}/${USER}/common/faq_list`, GET, true, {})
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const contactUs = (body) => {
  return request(`${BASE_URL}/${USER}/auth/website_contact_us`, POST, false, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------Practice Section------------------>

export const practiceList = (body) => {
  return request(`${BASE_URL}/${USER}/home/practice_list`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const add_practice_answer = (body) => {
  return request(`${BASE_URL}/${USER}/practice-answer/add_practice_answer`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const practice_answer_list = (body) => {
  return request(`${BASE_URL}/${USER}/practice-answer/practice_answer_list`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

// <-----------------My Course Details Section------------------>

export const myCourseDetails = (body) => {
  return request(`${BASE_URL}/${USER}/my-courses/my_course_list`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};



// <-----------------Setting Section------------------>

export const getUserDetails = (body) => {
  let data={...body,type:'W'}
    return request(`${BASE_URL}/${USER}/auth/user_details`, POST, true, data)
      .then((response) => {
        return Decryption(response.data);
      })
      .catch((error) => {
        throw error;
      });
  };
  

export const editProfile = (body) => {
  return request(`${BASE_URL}/${USER}/auth/edit_profile`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const changePassword = (body) => {
  return request(`${BASE_URL}/${USER}/auth/change_password`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const leaderboardDetails = (body) => {
  return request(`${BASE_URL}/${USER}/common/leaderboard`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const getBronzeDetails = (body) => {
  return request(`${BASE_URL}/${USER}/bronze/bronze_list`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};


// <-----------------addDownloads Section------------------>

export const addDownloads = (body) => {
  return request(`${BASE_URL}/${USER}/common/add_download`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};


// <-----------------Enble Disable Notification------------------>

export const enbleDisable = (body) => {
  return request(`${BASE_URL}/${USER}/auth/update_notification_privacy_status`, POST, true, body)
    .then((response) => {
      return Decryption(response.data);
    })
    .catch((error) => {
      throw error;
    });
};